import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import EpisodeList from './components/EpisodeList';
import DynamicMetaTags from './components/DynamicMetaTags';
import { listEpisodes, getTranscript } from './utils/s3Api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AppContent() {
  const [episodes, setEpisodes] = useState([]);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = useQuery();
  const selectedEpisodeNumber = query.get('episode');
  const location = useLocation();

  useEffect(() => {
    window.analytics.page();
  }, [location.pathname, location.search]);

  const handleSelectEpisode = useCallback(async (episode) => {
    try {
      if (!episode.transcript) {
        console.log('Fetching transcript for episode:', episode.episodeNumber);
        const transcript = await getTranscript(episode.key);
        const updatedEpisode = { ...episode, transcript };
        setEpisodes(prevEpisodes => 
          prevEpisodes.map(ep => ep.key === episode.key ? updatedEpisode : ep)
        );
        setCurrentEpisode(updatedEpisode);
      } else {
        setCurrentEpisode(episode);
      }
    } catch (err) {
      console.error('Error fetching transcript:', err);
      setError('Failed to load episode transcript. Please try again later.');
      window.analytics.track('Error Fetching Transcript', {
        error: err.message,
        stack: err.stack,
        episodeNumber: episode.episodeNumber
      });
    }
  }, []);

  useEffect(() => {
    async function fetchEpisodes() {
      setIsLoading(true);
      setError(null);
      try {
        console.log('Fetching episodes...');
        const fetchedEpisodes = await listEpisodes();
        console.log('Fetched episodes:', fetchedEpisodes);
        setEpisodes(fetchedEpisodes);
        if (selectedEpisodeNumber) {
          const episode = fetchedEpisodes.find(ep => ep.episodeNumber.toString().padStart(2, '0') === selectedEpisodeNumber);
          if (episode) {
            await handleSelectEpisode(episode);
          }
        }
      } catch (err) {
        console.error('Error fetching episodes:', err);
        setError('Failed to load episodes. Please try again later.');
        window.analytics.track('Error Fetching Episodes', {
          error: err.message,
          stack: err.stack
        });
      } finally {
        setIsLoading(false);
      }
    }
    fetchEpisodes();
  }, [selectedEpisodeNumber, handleSelectEpisode]);

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <DynamicMetaTags episode={currentEpisode} />
      <Header />
      {isLoading ? (
        <div>Loading episodes...</div>
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <EpisodeList 
          episodes={episodes} 
          onSelectEpisode={handleSelectEpisode}
          currentEpisode={currentEpisode}
        />
      )}
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;