import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, ShareIcon, CodeIcon, PlayIcon, PauseIcon, XIcon } from '@heroicons/react/solid';

const EpisodeList = ({ episodes, onSelectEpisode, currentEpisode }) => {
  const [expandedEpisode, setExpandedEpisode] = useState(null);
  const [playingEpisode, setPlayingEpisode] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [embedEpisode, setEmbedEpisode] = useState(null);
  const audioRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const episode = episodes.find(ep => ep.episodeNumber.toString().padStart(2, '0') === hash);
      if (episode) {
        setExpandedEpisode(episode.key);
        onSelectEpisode(episode);
        setTimeout(() => {
          const element = document.getElementById(`episode-${hash}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    }
  }, [location, episodes, onSelectEpisode]);

  const toggleExpand = (episode) => {
    if (expandedEpisode === episode.key) {
      setExpandedEpisode(null);
    } else {
      setExpandedEpisode(episode.key);
      onSelectEpisode(episode);
    }
  };

  const togglePlayPause = (episode) => {
    if (playingEpisode === episode.key) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setPlayingEpisode(episode.key);
      setIsPlaying(true);
      setCurrentTime(0);
      setDuration(0);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleSeek = (e) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    audioRef.current.currentTime = time;
  };

  useEffect(() => {
    if (playingEpisode) {
      const episode = episodes.find(ep => ep.key === playingEpisode);
      if (episode && audioRef.current) {
        audioRef.current.src = episode.url;
        audioRef.current.play();
      }
    }
  }, [playingEpisode, episodes]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        alert('Copied to clipboard!');
      }, (err) => {
        console.error('Could not copy text: ', err);
        fallbackCopyTextToClipboard(text);
      });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      alert('Copied to clipboard!');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      alert('Failed to copy. Please copy it manually: ' + text);
    }

    document.body.removeChild(textArea);
  };

  const copyShareLink = (episode) => {
    const shareUrl = `${window.location.origin}${window.location.pathname}#${episode.episodeNumber.toString().padStart(2, '0')}`;
    copyToClipboard(shareUrl);
  };



  
  const embedCode = (episodeNumber) => 
    `<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="${window.location.origin}/embed/${episodeNumber.toString().padStart(2, '0')}"></iframe>`;

  const showEmbed = (episode) => {
    setEmbedEpisode(episode);
    setShowEmbedModal(true);
  };

  return (
    <div className="bg-secondary-background rounded-lg p-6 mb-6 shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Episodes</h2>
      {episodes.map((episode) => (
        <div 
          key={episode.key} 
          id={`episode-${episode.episodeNumber.toString().padStart(2, '0')}`}
          className="mb-6 p-4 border-b border-gray-700 last:border-b-0"
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-xl font-semibold">Episode {episode.episodeNumber.toString().padStart(2, '0')}</h3>
            
            <div className="flex space-x-2">
              {/* video play pause */}
              <button 
                onClick={() => togglePlayPause(episode)} 
                className="bg-primary text-background p-2 rounded hover:bg-opacity-80 transition-colors"
                title={playingEpisode === episode.key && isPlaying ? "Pause" : "Play"}
              >
                {playingEpisode === episode.key && isPlaying ? 
                  <PauseIcon className="h-5 w-5" /> : 
                  <PlayIcon className="h-5 w-5" />
                }
              </button>
              <button 
                onClick={() => toggleExpand(episode)} 
                className="bg-primary text-background p-2 rounded hover:bg-opacity-80 transition-colors"
                title={expandedEpisode === episode.key ? "Hide Transcript" : "Show Transcript"}
              >
                {expandedEpisode === episode.key ? 
                  <ChevronUpIcon className="h-5 w-5" /> : 
                  <ChevronDownIcon className="h-5 w-5" />
                }
              </button>
              <button 
                onClick={() => copyShareLink(episode)} 
                className="bg-primary text-background p-2 rounded hover:bg-opacity-80 transition-colors"
                title="Copy Share Link"
              >
                <ShareIcon className="h-5 w-5" />
              </button>
              <button 
                onClick={() => showEmbed(episode)} 
                className="bg-primary text-background p-2 rounded hover:bg-opacity-80 transition-colors"
                title="Show Embed Code"
              >
                <CodeIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          <p className="text-gray-400 mb-2">Published: {new Date(episode.date).toLocaleDateString()}</p>
          {playingEpisode === episode.key && (
            <div className="mt-4">
              <input 
                type="range" 
                min="0" 
                max={duration} 
                value={currentTime} 
                onChange={handleSeek} 
                className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-400">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
            </div>
          )}
          {expandedEpisode === episode.key && currentEpisode && currentEpisode.key === episode.key && currentEpisode.transcript && (
            <div className="mt-4 bg-background p-4 rounded">
              <h4 className="font-semibold mb-2">Transcript</h4>
              <video src = {episode.url.replace("mp3","mp4").replace("audio","videos")} className="w-1280 h-720" controls></video>
              <p className="whitespace-pre-wrap">{currentEpisode.transcript}</p>
            </div>
          )}
        </div>
      ))}
      <audio 
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => setIsPlaying(false)}
        hidden
      />
      {showEmbedModal && embedEpisode && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-secondary-background rounded-lg p-6 max-w-lg w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Embed Episode {embedEpisode.episodeNumber.toString().padStart(2, '0')}</h3>
              <button 
                onClick={() => setShowEmbedModal(false)}
                className="text-gray-400 hover:text-white"
              >
                <XIcon className="h-6 w-6" />
              </button>
            </div>
            <textarea 
              readOnly 
              value={embedCode(embedEpisode.episodeNumber)}
              className="w-full h-24 p-2 bg-background text-white border border-gray-700 rounded mb-4"
            />
            <button 
              onClick={() => copyToClipboard(embedCode(embedEpisode.episodeNumber))}
              className="bg-primary text-background px-4 py-2 rounded hover:bg-opacity-80 transition-colors"
            >
              Copy Embed Code
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EpisodeList;