const S3_BUCKET_URL = 'https://s3.us-east-1.amazonaws.com/media.0hour.news';

export async function listEpisodes() {
  try {
    console.log('Fetching episodes from:', S3_BUCKET_URL);
    const response = await fetch(S3_BUCKET_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();
    console.log('Received response:', text.substring(0, 200) + '...'); // Log first 200 characters

    // Parse the text response
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, "text/xml");
    
    const contents = xmlDoc.getElementsByTagName('Contents');
    console.log('Number of Contents elements:', contents.length);
    
    let episodes = Array.from(contents)
      .filter(content => {
        const key = content.querySelector('Key').textContent;
        return key.endsWith('.mp3');
      })
      .map(content => {
        const key = content.querySelector('Key').textContent;
        const date = new Date(content.querySelector('LastModified').textContent);
        return { key, url: `${S3_BUCKET_URL}/${key}`, date };
      })
      .sort((a, b) => a.date - b.date); // Sort by date, oldest first

    // Assign episode numbers
    episodes = episodes.map((episode, index) => ({
      ...episode,
      episodeNumber: index,
    }));

    // Reverse the array to have newest episodes first
    episodes.reverse();

    console.log('Processed episodes:', episodes);
    return episodes;
  } catch (error) {
    console.error('Error in listEpisodes:', error);
    console.error('Error details:', {
      name: error.name,
      message: error.message,
      stack: error.stack
    });
    window.analytics.track('Error Listing Episodes', {
      error: error.message,
      stack: error.stack
    });
    throw error;
  }
}

export async function getTranscript(episodeKey) {
  const transcriptKey = episodeKey.replace('audio/', 'transcripts/').replace('.mp3', '.md');
  const transcriptUrl = `${S3_BUCKET_URL}/${transcriptKey}`;

  try {
    console.log('Fetching transcript from:', transcriptUrl);
    const response = await fetch(transcriptUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();
    console.log('Received transcript, length:', text.length);
    return text;
  } catch (error) {
    console.error('Error fetching transcript:', error);
    window.analytics.track('Error Fetching Transcript', {
      error: error.message,
      stack: error.stack,
      episodeKey
    });
    throw error;
  }
}