import React from 'react';

const Header = () => (
  <header className="text-center mb-8">
    <div className="flex justify-center items-center mb-4">
      <img 
        src="/zero-hour-news-logo.jpg" 
        alt="Zero Hour News Logo" 
        className="w-16 h-16 mr-4 rounded-full"
      />
      <h1 className="text-4xl font-bold text-primary">Zero Hour News</h1>
    </div>
    <p className="text-xl text-gray-400">Your daily dose of cyber security news</p>
  </header>
);

export default Header;