import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const DynamicMetaTags = ({ episode }) => {
  const location = useLocation();
  const baseUrl = 'https://0hour.news';
  const currentUrl = `${baseUrl}${location.pathname}${location.search}`;
  
  const title = episode 
    ? `Zero Hour News - Episode ${episode.episodeNumber.toString().padStart(2, '0')}`
    : 'Zero Hour News';
  const description = episode
    ? `Listen to Episode ${episode.episodeNumber.toString().padStart(2, '0')} of Zero Hour News. ${episode.description || 'Get your daily dose of cyber security news.'}`
    : 'Get your daily dose of cyber security news with Zero Hour News. Short, informative podcasts to keep you updated on the latest in cybersecurity.';
  const imageUrl = `${baseUrl}/zero-hour-news-logo.jpg`;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={currentUrl} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={currentUrl} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageUrl} />
      </Helmet>
    </HelmetProvider>
  );
};

export default DynamicMetaTags;